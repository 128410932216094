import { css } from '@emotion/react';
import { FC, useCallback, useRef, useState } from 'react';

import TopButton from '../__assets/common/TopButton.svg';

import { ModalBase } from './common/Modal';
import { SampleImage } from './common/sampleImage';

const innerStyle = css`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5em;
`;
const innerStyleSP = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;
`;

const letterAreaStyle = css`
  width: min(calc((56.627vw - 2em) / 3 * 3 * 387 / 487), calc(((72vh - 2em) / 3) * 387 / 487 * 3));
  max-width: calc(122px * 3 + 2em);
  column-gap: 1vw;
  margin-right: 2em;
  /* height: 56.627vw;
  max-height: 72vh; */
  display: flex;
  flex-wrap: wrap;
  row-gap: 1em;
  column-gap: 1em;
`;
const letterAreaStyleSP = css`
  width: 90vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1em;
  column-gap: 1em;
`;
const letterStyle = (isActive: boolean) => css`
  height: min(153px, calc((56.627vw - 5em) / 3));
  max-height: min(153px, calc((72vh - 5em) / 3));
  ${isActive ? '' : 'filter:brightness(0.8);'};
`;
const letterStyleSP = (isActive: boolean) => css`
  width: calc(45vw - 1em);
  ${isActive ? '' : 'filter:brightness(0.8);'};
`;
const sampleStyle = css`
  max-width: min(387px, 45vw);
  height: min(487px, 56.627vw);
  max-height: 72vh;
`;
const sampleStyleSP = css`
  width: 90vw;
  padding: 1em;
`;
interface Props {
  close: () => void;
  isSP: boolean;
  id: string;
  firstSample: string;
  letters: string[];
  isOn: boolean;
}

export const LetterModal: FC<Props> = ({ close, isSP, id, firstSample, letters, isOn }) => {
  const [currentSample, setSample] = useState<string>(firstSample);
  const ref = useRef<HTMLDivElement>(null);
  const onClick = useCallback(
    (letter: string) => () => {
      setSample(letter);
      if (isSP && ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
    },
    [isSP]
  );
  const data = {
    letter: (letter: string) => ({
      css: isSP ? letterStyleSP(letter === currentSample) : letterStyle(letter === currentSample),
      src: `member/${letter}.jpg`,
      alt: '年賀状',
      onClick: onClick(letter),
    }),
    sample: {
      ref,
      css: isSP ? sampleStyleSP : sampleStyle,
    },
  };

  return (
    <ModalBase close={close} buttonSrc={TopButton} isSP={isSP} isOn={isOn} key="lettermodal">
      <div css={isSP ? innerStyleSP : innerStyle}>
        <div css={isSP ? letterAreaStyleSP : letterAreaStyle}>
          {letters?.map((letter, i) => {
            return (
              <div key={`nenga-${letter}-${i}`}>
                <img {...data.letter(letter)} />
              </div>
            );
          })}
        </div>
        <div {...data.sample}>
          <SampleImage {...{ isSP, currentSample, samples: letters, id }} />
        </div>
      </div>
    </ModalBase>
  );
};
