import { css } from '@emotion/react';
import { FC } from 'react';

import img11 from '../../../__assets/pc/message_1-1.svg';
import img12 from '../../../__assets/pc/message_1-2.svg';
import img13 from '../../../__assets/pc/message_1-3.svg';

import { TransformWrapper } from './transformWrapper';

interface Props {
  scrolltop: number;
  defScale: number;
}

const imgStyle1 = css`
  height: 100%;
  width: 100%;
  max-width: 80%;
  max-height: 70%;
  margin-top: 2em;
  margin-bottom: min(5%, 5vh);
`;
const imgStyle2 = css`
  height: 100%;
  width: 100%;
  max-width: 50%;
  max-height: 10%;
  margin-bottom: min(5%, 5vh);
`;
const imgStyle3 = css`
  height: 100%;
  width: 100%;
  max-width: 20%;
  max-height: 8%;
`;

const containerStyle = css`
  height: calc(100% - 200px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 200px;
  @media (max-height: 840px) {
    margin-bottom: 150px;
    height: calc(100% - 150px);
  }
`;

export const TopPage: FC<Props> = ({ scrolltop, defScale }) => {
  return (
    <TransformWrapper {...{ scrolltop, defScale, height: '100%' }}>
      <div css={containerStyle}>
        <img css={imgStyle1} src={img11} alt="message-img11" />
        <img css={imgStyle2} src={img12} alt="message-img12" />
        <img css={imgStyle3} src={img13} alt="message-img13" />
      </div>
    </TransformWrapper>
  );
};
