import { css } from '@emotion/react';
import { FC, TouchEvent } from 'react';

const wideContent = css`
  width: 608px;
  @media (max-width: 1300px) {
    height: auto;
    width: 33.0434vw;
  }
  @media (max-height: 840px) {
    height: 273.8vh;
    width: 72.37843vh;
  }
`;
const narrowContent = css`
  width: 608px;
  @media (max-height: 840px) {
    height: 273.8vh;
    width: 72.37843vh;
  }
  @media (max-width: 1300px) {
    height: auto;
    width: 33.0434%;
  }
`;

export const contentL = (isWide: boolean) => (isWide ? wideContent : narrowContent);

interface Props {
  src: string;
  isWide: boolean;
}
export const BgMovieL: FC<Props> = ({ src, isWide }) => {
  const onTouchMove = (e: TouchEvent<HTMLVideoElement>) => {
    e.preventDefault();
  };

  return (
    <video
      onScrollCapture={onTouchMove}
      css={contentL(isWide)}
      id="video"
      poster=""
      webkit-playsinline="true"
      playsInline
      muted
      autoPlay
      loop
    >
      <source css={contentL(isWide)} src={src} type="video/mp4" />
      <p>動画を再生できる環境ではありません。</p>
    </video>
  );
};
