export const getScale = (obj: { near: number; far: number; position: number; scrolltop: number }) => {
  const offset = Math.abs(obj.position - obj.scrolltop);
  if (offset < obj.near) {
    return 1;
  }
  if (offset > obj.far) {
    return 0.3;
  }
  const a = -0.4 / (obj.far - obj.near);
  const b = 1 - obj.near * a;

  return offset * a + b;
};
