import { css } from '@emotion/react';
import { FC } from 'react';

import { fadeIn } from '../../styles/animation';

interface Props {
  isSP: boolean;
  currentSample: string | undefined;
  samples: string[];
  id: string;
}
const imageStyle = (isSP: boolean, visible: boolean) => css`
  ${isSP ? 'width:100%;' : 'height: 100%;'}
  animation:${fadeIn} 0.5s ease-in-out;
  ${!visible ? 'display:none;' : ''}
`;
export const SampleImage: FC<Props> = ({ isSP, samples, currentSample, id }) => {
  return (
    <>
      {samples.map((sample) => (
        <img css={imageStyle(isSP, sample === currentSample)} src={`./output/${id}/${sample}.png`} key={sample} />
      ))}
    </>
  );
};
