import { css } from '@emotion/react';
import { FC } from 'react';

import Oro from '../../__assets/common/mark.svg';

const oroIconStyle = css`
  width: 50px;
`;
const containerStyle = css`
  position: absolute;
  top: 1em;
  left: 2em;
  z-index: 100000;
`;
const oroIconStyleSP = css`
  width: 30px;
`;
const containerStyleSP = css`
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 100000;
`;

interface Props {
  isSP: boolean;
}

export const OroHpLink: FC<Props> = ({ isSP }) => {
  return (
    <a css={isSP ? containerStyleSP : containerStyle} href="https://www.oro.com/">
      <img css={isSP ? oroIconStyleSP : oroIconStyle} src={Oro} alt="oro icon" />
    </a>
  );
};
