import { css } from '@emotion/react';
import { FC } from 'react';

import siteButton from '../__assets/common/siteButton.svg';

import { ModalBase } from './common/Modal';
import { ShrinkableSpacer } from './common/shrinkableSpacer';

interface Props {
  close: () => void;
  isSP: boolean;
  isOn: boolean;
}

const messageStyle = (isSP: boolean) => css`
  font-size: ${isSP ? '18px' : '40px'};
  letter-spacing: 0.04em;
  line-height: 2;
  text-align: center;
  margin-bottom: ${isSP ? '25px' : '50px'};
  white-space: pre-wrap;
`;
const messageStyle2 = (isSP: boolean) => css`
  ${messageStyle(isSP)}
  font-size: ${isSP ? '14px' : '26px'};
  margin-bottom: 0px;
`;

const message =
  '株式会社オロの年賀状2022ページに訪問いただき、\nありがとうございます。\n本ページは公開を終了いたしました。';
const message2 = 'また来年もお楽しみに！';

export const ExpireModal: FC<Props> = ({ close, isSP, isOn }) => {
  return (
    <ModalBase close={close} buttonSrc={siteButton} isSP={isSP} isOn={isOn} expired>
      <div css={messageStyle(isSP)} children={message} />
      <div css={messageStyle2(isSP)} children={message2} />
      <ShrinkableSpacer height="15%" />
    </ModalBase>
  );
};
