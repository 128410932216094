import { css } from '@emotion/react';
import { FC } from 'react';

const shrinkStyle = (height: string) =>
  css`
    flex-shrink: 100;
    height: ${height};
  `;
interface Props {
  height: string;
}
export const ShrinkableSpacer: FC<Props> = ({ height }) => {
  return <div css={shrinkStyle(height)} />;
};
