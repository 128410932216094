import { css } from '@emotion/react';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import { getScale } from '../../../util/util';

interface Props {
  scrolltop: number;
  defScale: number;
  height?: string;
}

const messageStyle = (height?: string) => css`
  width: 100%;
  ${height ? `height:${height}` : '30%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
`;

const transformWrapper = (defScale: number, scale: number, height?: string): CSSProperties => ({
  width: `${defScale}%`,
  height: height || '30%',
  transform: `scale(${scale}, ${scale})`,
  opacity: Math.sqrt(scale ** 2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const TransformWrapper: FC<Props> = ({ scrolltop, defScale, children, height }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState(0);
  const [scale, setScale] = useState<number>(1);
  const heightCalcBase = window.innerHeight;

  useEffect(() => {
    const target = ref.current;
    if (target) {
      setPosition(target.offsetTop + target.getBoundingClientRect().height / 2);
    }
    setScale(getScale({ near: heightCalcBase * 0.1, far: heightCalcBase * 0.5, position, scrolltop }));
  }, [position, heightCalcBase, scrolltop]);

  return (
    <div ref={ref} css={messageStyle(height)}>
      <div style={transformWrapper(defScale, scale, height)}>{children}</div>
    </div>
  );
};
