import { css } from '@emotion/react';
import { FC } from 'react';

import copyright from '../../__assets/common/whiteCopyright.svg';

const modalStyle = (isOn: boolean, expired: boolean) => css`
  position: absolute;
  top: ${isOn ? 0 : '100%'};
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: top 0.5s;
  ${expired ? 'display: flex;' : ''}
  ::-webkit-scrollbar {
    display: none;
  }
`;
const innerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;
const innerStyleExpired = css`
  text-align: center;
  width: 100%;
`;

const buttonStyle = (isSP: boolean) => css`
  background: transparent;
  transition: border-color 0.5s, background-color 0.5s;
  border: solid white 2px;
  border-radius: 44px;
  font-size: ${isSP ? '14px' : '24px'};
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  :hover {
    border-color: #c72012;
    background-color: #c72012;
  }
`;

const copyrightStyle = css`
  margin-top: 0.5em;
  max-height: 5vw;
  max-width: 60vw;
`;

interface Props {
  close: () => void;
  buttonSrc: string;
  isSP: boolean;
  isOn: boolean;
  expired?: boolean;
}

export const ModalBase: FC<Props> = ({ children, close, isSP, buttonSrc, isOn, expired }) => {
  const data = {
    closebutton: { css: buttonStyle(isSP), onClick: close },
  };

  return (
    <div css={modalStyle(isOn, expired || false)}>
      <div css={expired ? innerStyleExpired : innerStyle}>
        {children}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div>
            <button {...data.closebutton}>オロの企業サイトを見る</button>
          </div>
          <img css={copyrightStyle} src={copyright} />
        </div>
      </div>
    </div>
  );
};
