import { css } from '@emotion/react';
import axios, { AxiosRequestConfig } from 'axios';
import { FC, useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import useMedia from 'use-media';
import xml2js from 'xml2js';

import { IdContext, UAContext } from '../context/context';

import { ExpireModal } from './ExpireModal';
import { LetterModal } from './letterModal';
import { NengaPC } from './pc/nengaPC';
import { NengaSP } from './sp/nengaSP';

const container = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const fetchXml = async (xmlPath: string) => {
  const config: AxiosRequestConfig = {
    transformResponse: [
      (xml: xml2js.convertableToString) => {
        let jsonData;
        const parser = new xml2js.Parser({
          async: false,
          explicitArray: false,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parser.parseString(xml, (error: any, json: string) => {
          if (error) {
            console.error(error);
          }
          jsonData = json;
        });

        return jsonData;
      },
    ],
  };

  return axios.get(xmlPath, config);
};

type letterType = {
  'company-code': string;
};

export const Nenga: FC = () => {
  const isSP = useMedia({ 'max-width': '789px', 'max-aspect-ratio': '605/917' }, true);
  const [modal, setModal] = useState(false);
  const [letters, setLetters] = useState<string[]>([]);
  const expired = true;
  const toORO = () => {
    window.location.href = 'https://www.oro.com';
  };
  const [companyId, setID] = useState('');
  const [isMacSafari, setIsMacSafari] = useState({ isMac: false, isSafari: false });
  useEffect(() => {
    if (window.location.search) {
      const id = window.location.search.split('?')[1].split('&')[0];
      setID(id);
    }
    const parser = new UAParser();
    const os = parser.getOS();
    const isMac = os.name === 'Mac OS';
    const browser = parser.getBrowser();
    const isSafari = browser.name === 'Safari';
    setIsMacSafari({ isMac, isSafari });
  }, []);

  useEffect(() => {
    if (companyId) {
      const xmlPath = `output/${companyId}/list.xml`;
      fetchXml(xmlPath)
        .then((res) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const { nenga } = res.data.list as unknown as { nenga: letterType | letterType[] };
          const arr: letterType[] = Array.isArray(nenga) ? nenga : [nenga];
          setLetters(arr.map((letter) => letter['company-code']));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [companyId]);

  return (
    <div css={container}>
      <UAContext.Provider value={isMacSafari}>
        <IdContext.Provider value={letters.length > 0}>
          {isSP ? (
            <NengaSP open={() => setModal(true)} expired={expired} isSP={isSP} />
          ) : (
            <NengaPC open={() => setModal(true)} expired={expired} isSP={isSP} />
          )}
          {modal && (
            <LetterModal
              isSP={isSP}
              close={() => setModal(false)}
              id={companyId}
              letters={letters}
              isOn={modal}
              firstSample={letters[0]}
            />
          )}
          {expired && <ExpireModal isOn={expired} isSP={isSP} close={toORO} />}
        </IdContext.Provider>
      </UAContext.Provider>
    </div>
  );
};
