import { css } from '@emotion/react';
import { FC } from 'react';

import { Nenga } from './components/nenga';

const globalContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

export const App: FC = () => {
  return (
    <div css={globalContainer}>
      <Nenga />
    </div>
  );
};
