import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import reportWebVitals from './_test/reportWebVitals';

const Build = (parent: HTMLDivElement) => {
  ReactDOM.render(<App />, parent);
};

window.onload = () => {
  const parent = document.getElementById('root') as HTMLDivElement;
  if (parent) Build(parent);
  reportWebVitals();
};
