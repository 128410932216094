import { keyframes } from '@emotion/react';

export const fadeIn = keyframes`
0%,20%{
    opacity:0;
}
100%{
    opacity:1;
}
`;

export const delayedFadeIn = keyframes`
0%,50%{
    opacity:0;
}
100%{
    opacity:1;
}
`;

export const fadeOut = keyframes`
0%,50%{
    opacity:1;
}
80%{
    opacity:0;
}
100%{
    opacity:0;
}
`;
