import { css } from '@emotion/react';
import { FC } from 'react';

import img1 from '../../../__assets/sp/message_1.svg';

import { TransformWrapper } from './transformWrapper';

interface Props {
  scrolltop: number;
  defScale: number;
}

const imgStyle = css`
  width: 100%;
  padding-bottom: 10%;
`;

export const TopPage: FC<Props> = ({ scrolltop, defScale }) => {
  return (
    <TransformWrapper {...{ scrolltop, defScale, height: '100%' }}>
      <img css={imgStyle} src={img1} alt="message-1" />
    </TransformWrapper>
  );
};
