/* eslint-disable @typescript-eslint/no-unsafe-return */
import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import { FC, lazy, Suspense, useEffect, useState } from 'react';
import useMedia from 'use-media';

import bg from '../../__assets/pc/bg.png';
import { fadeOut } from '../../styles/animation';
import { OroHpLink } from '../common/OroHpLink';

import { contentL } from './movie/bgMovieL';
import { contentR } from './movie/bgMovieR';
import { TextContainer } from './textContainer';

const container = css`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const wideCropWrapper = css`
  position: relative;
  min-width: 1838px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1300px) {
    min-width: 141.3vw;
  }
  @media (max-height: 840px) {
    min-width: 219.04vh;
  }
`;
const narrowCropWrapper = css`
  position: relative;
  min-width: 1838px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-height: 840px) {
    min-width: 219.04vh;
  }
  @media (max-width: 1300px) {
    min-width: 141.3vw;
  }
`;

const cropWrapper = (isWide: boolean) => (isWide ? wideCropWrapper : narrowCropWrapper);

const wideBgStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  height: 2300px;
  z-index: -2;
  background-image: url(${bg});
  width: max(1438px, 100vw);
  background-repeat: repeat;
  @media (max-width: 1300px) {
    height: 176.625vw;
  }
  @media (max-height: 840px) {
    height: calc(230000vh / 840);
  }
`;
const narrowBgStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: contain;
  height: 2300px;
  z-index: -2;
  background-image: url(${bg});
  width: max(1438px, 100vw);
  background-repeat: repeat;
  @media (max-height: 840px) {
    height: calc(230000vh / 840);
  }
  @media (max-width: 1300px) {
    height: 176.625vw;
  }
`;

const bgStyle = (isWide: boolean) => (isWide ? wideBgStyle : narrowBgStyle);

const marginAreaContainer = css`
  position: relative;
  flex-grow: 1;
  height: 100vh;
`;
const spinnerWrapper = css`
  position: relative;
  top: 50%;
  left: 50%;
  transform: trasnslate(-50%, -50%);
`;
const fadeShelter = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 1000;
  background: #f8c500;
  animation: ${fadeOut} 2s ease-in-out;
`;

interface Props {
  open: () => void;
  expired: boolean;
  isSP: boolean;
}
const BgContainer = lazy(() => import('./movie/bgContainer'));

export const NengaPC: FC<Props> = ({ open, expired, isSP }) => {
  const isWide = useMedia({ 'min-aspect-ratio': '1300/840' });
  const [fader, setFader] = useState(true);
  useEffect(() => {
    setFader(true);
    setTimeout(() => {
      setFader(false);
    }, 1500);
  }, []);

  return (
    <div css={container}>
      <div css={cropWrapper(isWide)}>
        <Suspense
          fallback={
            <>
              <div css={contentL(isWide)} />
              <div css={marginAreaContainer}>
                <TextContainer isWide={isWide} isActive={false} />
              </div>
              <div css={contentR(isWide)}>
                <div css={spinnerWrapper}>
                  <CircularProgress
                    sx={{
                      color: '#263833',
                      animationDuration: '2.1s',
                      '& .MuiCircularProgress-circle': { animationDuration: '2.1s' },
                    }}
                    thickness={1.5}
                  />
                </div>
              </div>
            </>
          }
        >
          <BgContainer isWide={isWide} open={open} expired={expired}>
            <div css={marginAreaContainer}>
              <TextContainer isWide={isWide} isActive />
            </div>
          </BgContainer>
        </Suspense>
        <div css={bgStyle(isWide)} />
      </div>
      {fader && <div css={fadeShelter} />}
      <OroHpLink isSP={isSP} />
    </div>
  );
};
