/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { css } from '@emotion/react';
import { FC, lazy, Suspense, useState } from 'react';

import { UAContext } from '../../context/context';
import { OroHpLink } from '../common/OroHpLink';

import { Spinner } from './movie/Spinner';
import { TextContainer } from './textContainer';

const container = (isMac: boolean) => css`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 166vw;
  overflow: hidden;
  ${isMac ? 'background:#f8c500;' : ''}
`;
const BgContainer = lazy(() => import('./movie/bgContainer'));
interface Props {
  open: () => void;
  expired: boolean;
  isSP: boolean;
}
export const NengaSP: FC<Props> = ({ open, expired, isSP }) => {
  const [scroll, setScroll] = useState(0);

  return (
    <UAContext.Consumer>
      {(isMacSafari) => (
        <div css={container(isMacSafari.isMac)}>
          <TextContainer scroll={scroll} setScroll={(sc: number) => setScroll(Math.max(0, sc))} />
          <Suspense fallback={<Spinner />}>
            <BgContainer scroll={scroll} open={open} expired={expired} />
            <OroHpLink isSP={isSP} />
          </Suspense>
        </div>
      )}
    </UAContext.Consumer>
  );
};
