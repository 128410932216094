import { css } from '@emotion/react';
import { FC, useEffect, useRef, useState } from 'react';

/* import msg2 from '../../__assets/sp/message_2.svg';
import msg3 from '../../__assets/sp/message_3.svg';
import msg4 from '../../__assets/sp/message_4.svg';
import msg5 from '../../__assets/sp/message_5.svg';
import msg6 from '../../__assets/sp/message_6.svg';
import { EndPage } from './messages/end';
import { Message } from './messages/message'; */
import { UAContext } from '../../context/context';
import { fadeIn } from '../../styles/animation';

import { TopPage } from './messages/top';

const container = css`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: min(100vw, 35%);
  animation: ${fadeIn} 1.5s ease-in-out;
`;
const wrapper = css`
  width: 80vw;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  ::-webkit-scrollbar {
    display: none;
  }
`;
interface Props {
  scroll: number;
  setScroll: (s: number) => void;
}
export const TextContainer: FC<Props> = ({ scroll, setScroll }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const target = ref.current;
    if (target) {
      setHeight(target.getBoundingClientRect().height / 2);
    }
  }, [scroll]);

  return (
    <div ref={ref} css={container}>
      <UAContext.Consumer>
        {(isMacSafari) => (
          <div
            css={wrapper}
            onWheelCapture={(e) => {
              if (isMacSafari.isMac && isMacSafari.isSafari) {
                const delta = e.deltaY;
                const abs = Math.abs(delta);
                if (abs < 150 && abs > 80) {
                  e.currentTarget.scrollBy({
                    top: (50 * delta) / abs,
                    left: 0,
                    behavior: 'smooth',
                  });
                }
              }
            }}
            onScroll={(e) => {
              setScroll(e.currentTarget.scrollTop);
            }}
          >
            <TopPage scrolltop={scroll + height} defScale={80} />
            {/* <Message src={msg2} alt="msg2" scrolltop={scroll + height} defScale={100} />
            <Message src={msg3} alt="msg3" scrolltop={scroll + height} defScale={100} />
            <Message src={msg4} alt="msg4" scrolltop={scroll + height} defScale={100} />
            <Message src={msg5} alt="msg5" scrolltop={scroll + height} defScale={100} />
            <Message src={msg6} alt="msg6" scrolltop={scroll + height} defScale={100} />
            <EndPage scrolltop={scroll + height} defScale={100} /> */}
          </div>
        )}
      </UAContext.Consumer>
    </div>
  );
};
