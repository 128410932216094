import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';

const loadingStyle = css`
  position: absolute;
  top: 55vh;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Spinner: FC = () => {
  return (
    <div css={loadingStyle}>
      <CircularProgress
        sx={{
          color: '#263833',
          animationDuration: '2.1s',
          '& .MuiCircularProgress-circle': { animationDuration: '2.1s' },
        }}
        thickness={1.5}
      />
    </div>
  );
};
