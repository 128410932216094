import { css } from '@emotion/react';
import { FC } from 'react';

const wideContent = css`
  width: 1232px;
  @media (max-width: 1300px) {
    height: auto;
    width: 66.9565%;
  }
  @media (max-height: 840px) {
    height: 273.8vh;
    width: 146.6615vh;
  }
`;
const narrowContent = css`
  width: 1232px;
  @media (max-height: 840px) {
    height: 273.8vh;
    width: 146.6615vh;
  }
  @media (max-width: 1300px) {
    height: auto;
    width: 66.9565%;
  }
`;

export const contentR = (isWide: boolean) => (isWide ? wideContent : narrowContent);

interface Props {
  src: string;
  isWide: boolean;
}
export const BgMovieR: FC<Props> = ({ src, isWide }) => {
  return (
    <video css={contentR(isWide)} id="video" poster="" webkit-playsinline="true" playsInline muted autoPlay loop>
      <source css={contentR(isWide)} src={src} type="video/mp4" />
      <p>動画を再生できる環境ではありません。</p>
    </video>
  );
};
