import { css } from '@emotion/react';
import { FC, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';

import animationData from '../../__assets/pc/animation/arrowAnimation.json';
/* import msg2 from '../../__assets/pc/message_2.svg';
import msg3 from '../../__assets/pc/message_3.svg';
import msg4 from '../../__assets/pc/message_4.svg';
import msg5 from '../../__assets/pc/message_5.svg';
import msg6 from '../../__assets/pc/message_6.svg'; */
import { UAContext } from '../../context/context';
import { fadeIn } from '../../styles/animation';

/* import { EndPage } from './messages/end';
import { Message } from './messages/message'; */
import { TopPage } from './messages/top';

const container = css`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  height: min(63.6vw, 827px);
  width: min(40vw, calc(100% + 520px));
  transform: translate(-50%, -50%);
  overflow: visible;
  z-index: 100;
  @media (max-height: 840px) {
    height: min(63.6vw, calc(100% - 13px));
    width: min(40vw, calc(100% + 520px), calc(100% + 67vh));
  }
`;
const fadeContainer = css`
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  height: min(63.6vw, 827px);
  width: min(40vw, calc(100% + 520px));
  transform: translate(-50%, -50%);
  overflow: visible;
  z-index: 10000;
  animation: ${fadeIn} 1.5s ease-in-out;
  @media (max-height: 840px) {
    height: min(63.6vw, calc(100% - 13px));
    width: min(40vw, calc(100% + 520px), calc(100% + 67vh));
  }
`;
const wrapper = css`
  height: 100%;
  position: relative;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const arrowAnimationStyle = (visible: boolean, isWide: boolean) =>
  css`
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 430px);
    display: ${visible ? 'block' : 'none'};
    overflow: hidden;
    animation: ${fadeIn} 2s ease-in;
    ${isWide
      ? `
    @media (max-width: 1300px) {
      transform: translate(-50%, 31.8vw);
    }
    @media (max-height: 840px) {
      transform: translate(-50%, calc(50vh + 10px));
    }
    `
      : `
      @media (max-height: 840px) {
        transform: translate(-50%, calc(50vh + 10px));
      }
        @media (max-width: 1300px) {
        transform: translate(-50%, 31.8vw);
      }
      `}
  `;

interface Props {
  isWide: boolean;
  isActive: boolean;
}
export const TextContainer: FC<Props> = ({ isWide, isActive }) => {
  const [scroll, setScroll] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const target = ref.current;
    if (target) {
      setHeight(target.getBoundingClientRect().height / 2);
    }
  }, [scroll]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div ref={ref} css={isActive ? container : fadeContainer}>
      <UAContext.Consumer>
        {(isMacSafari) => (
          <div
            css={wrapper}
            onWheelCapture={(e) => {
              if (isMacSafari.isMac && isMacSafari.isSafari) {
                const delta = e.deltaY;
                const abs = Math.abs(delta);
                if (abs < 150 && abs > 80) {
                  e.currentTarget.scrollBy({
                    top: (100 * delta) / abs,
                    left: 0,
                  });
                }
              }
            }}
            onScroll={(e) => {
              setScroll(e.currentTarget.scrollTop);
            }}
          >
            <TopPage scrolltop={scroll + height} defScale={100} />
            {/* <Message src={msg2} alt="msg2" scrolltop={scroll + height} defScale={100} />
            <Message src={msg3} alt="msg3" scrolltop={scroll + height} defScale={100} />
            <Message src={msg4} alt="msg4" scrolltop={scroll + height} defScale={100} />
            <Message src={msg5} alt="msg5" scrolltop={scroll + height} defScale={100} />
            <Message src={msg6} alt="msg6" scrolltop={scroll + height} defScale={100} />
            <EndPage scrolltop={scroll + height} defScale={100} /> */}
            {isActive && (
              <div css={arrowAnimationStyle(scroll < 100, isWide)}>
                <Lottie style={{ background: 'transparent' }} options={defaultOptions} height={150} width={30} />
              </div>
            )}
          </div>
        )}
      </UAContext.Consumer>
    </div>
  );
};
